var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar-title',{staticClass:"mt-4"},[_vm._v("Períodos de "),_c('strong',[_vm._v(_vm._s(_vm.empresaNom))])]),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"tablaPeriodos","label":"Periodos","headers":_vm.headers,"header-props":{ sortIcon: null },"items":_vm.periodos,"search":_vm.searchTablaPeriodos,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': _vm.itemsPerPageOptions
    },"items-per-page":_vm.itemPerPage},scopedSlots:_vm._u([{key:"item.esperado",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.esperado)))])]}},{key:"item.recibido",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.recibido)))])]}},{key:"item.saldo",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.saldo)))])]}},{key:"item.interes",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.interes)))])]}},{key:"item.deuda",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.deuda)))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchTablaPeriodos),callback:function ($$v) {_vm.searchTablaPeriodos=$$v},expression:"searchTablaPeriodos"}})],1),(_vm.periodos.length > 0)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"35%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"mr-2 to-right",attrs:{"color":"primary","loading":_vm.loadingExportExcel,"align":"end","outlined":""},on:{"click":_vm.exportExcel}},'v-btn',attrs,false),[_vm._v(" Exportar detalle ")])]}}],null,false,4143869972)})],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.getDetalle(true, item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar período")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.deletePeriodo(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Quitar período")])])]}},{key:"body.append",fn:function(ref){
    var headers = ref.headers;
return [_c('tr',{staticClass:"totales text-end"},_vm._l((headers),function(header,i){return _c('td',{key:i},[(header.value == 'periodo')?_c('div',[_vm._v("Totales por períodos")]):_vm._e(),(header.value == 'esperado')?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.addDecimals(_vm.totalEsperado)))])]):_vm._e(),(header.value == 'recibido')?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.addDecimals(_vm.totalRecibido)))])]):_vm._e(),(header.value == 'saldo')?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.addDecimals(_vm.totalSaldo)))])]):_vm._e(),(header.value == 'interes')?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.addDecimals(_vm.totalInteres)))])]):_vm._e(),(header.value == 'deuda')?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.addDecimals(_vm.totalDeuda)))])]):_c('div')])}),0)]}}],null,true)}),(_vm.openModalDetail)?_c('div',[_c('br'),_c('br'),_c('DetallePeriodosDDJJ',{attrs:{"actaId":_vm.actaId,"periodoProp":_vm.periodoId,"cantPeriodos":_vm.cantidadPeriodos},on:{"getDetalle":function($event){return _vm.getDetalle()}}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"textDelete":_vm.textDelete,"maxWidth":'35%',"openDelete":_vm.openDeleteDialog},on:{"update:openDelete":function($event){_vm.openDeleteDialog=$event},"update:open-delete":function($event){_vm.openDeleteDialog=$event},"onDeleteItem":function($event){return _vm.deletePeriodoConfirm()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }