<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="true" />
    <PageHeader
      :title="isEditing ? `${titleEdit} N° ${procesoId}` : titleNew"
    />
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"
          >Selección de empresas</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"
          >Generación de actas</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mx-auto my-12 mt-1 mb-2" max-width="1200" outlined>
            <v-container>
              <v-row class="pr-4" justify="end">
                <v-col cols="11" align="left">
                  <FiltersSelected
                    :filters="filtersApplied"
                    v-if="!showFilters"
                  />
                </v-col>
                <v-col cols="1" align="right">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="primary--text"
                        @click.stop="closeOpenFilters"
                      >
                        {{ closeFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>{{
                      !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-form
                  v-show="showFilters"
                  v-model="isFormValid"
                  ref="filters-form"
                  id="filters-form"
                  @submit.prevent="applyFilters()"
                >
                  <v-row>
                    <!-- Empresa -->
                    <v-col cols="12" sm="6" md="4" class="pb-0">
                      <v-autocomplete
                        ref="empresa-autocomplete"
                        cache-items
                        v-model="empresaSelected"
                        :items="empresas"
                        :search-input.sync="empresaTextInput"
                        item-text="value"
                        item-value="id"
                        outlined
                        dense
                        clearable
                        autocomplete="off"
                        label="Empresa"
                        hint="Nombre o CUIT"
                        persistent-hint
                      ></v-autocomplete>
                    </v-col>
                    <!-- Afiliado -->
                    <v-col cols="12" sm="6" md="4" class="pb-0">
                      <v-autocomplete
                        cache-items
                        v-model="afiliadoSelected"
                        :items="afiliados"
                        :search-input.sync="afiliadoTextInput"
                        item-text="value"
                        item-value="id"
                        outlined
                        dense
                        clearable
                        autocomplete="off"
                        label="Afiliado"
                        hint="Nombre, DNI o N° Afiliado"
                        persistent-hint
                      ></v-autocomplete>
                    </v-col>
                    <!-- Convenio -->
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete
                        v-if="filtersTop"
                        v-model="filtersTop.convenios.model"
                        :items="convenios"
                        id="convAutocomplete"
                        item-text="value"
                        item-value="id"
                        label="Convenio"
                        outlined
                        dense
                        clearable
                        autocomplete="off"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- Período desde -->
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        type="number"
                        onkeydown="return (event.keyCode !== 69 && 
                        event.keyCode !== 109 && 
                        event.keyCode !== 107 &&
                        event.keyCode !== 188 &&
                        event.keyCode !== 190)"
                        outlined
                        dense
                        label="Período DDJJ desde"
                        hint="Formato AAAAMM"
                        v-mask="'######'"
                        v-model="periodoDesde"
                        autocomplete="not"
                        :rules="
                          (periodoDesde <= periodoHasta &&
                            rules.periodoYyyyMm) || ['Formato incorrecto']
                        "
                      ></v-text-field>
                    </v-col>
                    <!-- Período hasta -->
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        outlined
                        dense
                        label="Período DDJJ hasta"
                        hint="Formato AAAAMM"
                        v-mask="'######'"
                        v-model="periodoHasta"
                        autocomplete="not"
                        :rules="
                          (periodoDesde <= periodoHasta &&
                            rules.periodoYyyyMm) || ['Formato incorrecto']
                        "
                      ></v-text-field>
                    </v-col>
                    <!-- Fecha desde -->
                    <v-col cols="12" sm="6" md="3" class="pb-0">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaDesdeSelected"
                            label="Fecha procesoDDJJ desde"
                            :append-icon="calendarIcon"
                            v-bind="attrs"
                            autocomplete="not"
                            v-mask="'##/##/####'"
                            hint="Formato DD/MM/AAAA"
                            @blur="
                              fechaDesde = parseDateToIso(fechaDesdeSelected)
                            "
                            outlined
                            clearable
                            dense
                            v-on="on"
                            :rules="
                              rules.required.concat(
                                new Date(parseDateToIso(fechaDesdeSelected)) <=
                                  new Date(parseDateToIso(fechaHastaSelected))
                              ) || 'Formato incorrecto'
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fechaDesde"
                          no-title
                          @change="fechaDesdeSelected = formatDate(fechaDesde)"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <!-- Fecha hasta -->
                    <v-col cols="12" sm="6" md="3" class="pb-0">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaHastaSelected"
                            label="Fecha procesoDDJJ hasta"
                            :append-icon="calendarIcon"
                            hint="Formato DD/MM/AAAA"
                            v-mask="'##/##/####'"
                            @blur="
                              fechaHasta = parseDateToIso(fechaHastaSelected)
                            "
                            outlined
                            autocomplete="not"
                            dense
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            :rules="
                              rules.required.concat(
                                new Date(parseDateToIso(fechaDesdeSelected)) <=
                                  new Date(parseDateToIso(fechaHastaSelected))
                              ) || 'Formato incorrecto'
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fechaHasta"
                          no-title
                          @change="fechaHastaSelected = formatDate(fechaHasta)"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- Tipo saldo -->
                    <v-col
                      cols="3"
                      sm="6"
                      md="2"
                      class=" d-flex py-0 justify-content-center"
                    >
                      <v-select
                        v-model="saldoSelected"
                        :items="saldo"
                        item-text="value"
                        item-value="id"
                        label="Saldo"
                        outlined
                        dense
                      >
                      </v-select>
                    </v-col>
                    <!-- Incluir registros fiscalizados -->
                    <v-col
                      cols="2"
                      md="3"
                      class="d-flex py-0 justify-content-center"
                    >
                      <v-checkbox
                        class="mt-1"
                        v-model="esFiscalizado"
                        label="Incluir registros fiscalizados"
                        id="registrosFiscalizados"
                      ></v-checkbox>
                    </v-col>
                    <!-- Fecha cálculo interés -->
                    <v-col
                      cols="3"
                      sm="6"
                      md="3"
                      class="d-flex py-0 justify-content-center"
                    >
                      <v-menu
                        v-model="menu5"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaInteresSelected"
                            label="Fecha cálculo interés"
                            :append-icon="calendarIcon"
                            v-mask="'##/##/####'"
                            :hint="
                              fechaInteresOnFocus
                                ? 'Formato DD/MM/AAAA'
                                : 'Se calcula el interés hasta la fecha indicada'
                            "
                            @blur="
                              (fechaInteres = parseDateToIso(
                                fechaInteresSelected
                              )),
                                (fechaInteresOnFocus = false)
                            "
                            outlined
                            clearable
                            autocomplete="off"
                            dense
                            v-bind="attrs"
                            v-on="on"
                            @focus="fechaInteresOnFocus = true"
                            persistent-hint
                            :rules="rules.required"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fechaInteres"
                          no-title
                          @change="
                            fechaInteresSelected = formatDate(fechaInteres)
                          "
                          @input="menu5 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <!-- determinar esperado -->
                    <v-col cols="3" md="3" class="py-0">
                      <v-checkbox
                        class="mt-0"
                        v-model="esperadoXCategoria"
                        label="Determinar esperado según el sueldo de la categoría"
                        id="registrosFiscalizados"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="1" class="py-0">
                      <v-tooltip top max-width="25%">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" v-bind="attrs" v-on="on">
                            {{ infoIcon }}
                          </v-icon>
                        </template>
                        <span
                          >En caso que el afiliado no tenga categoría o esta
                          tenga sueldo 0, se calculará desde la DDJJ
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="pr-4 pl-4" justify="end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      type="submit"
                      small
                      form="filters-form"
                      :disabled="loading"
                    >
                      Aplicar
                    </v-btn>
                  </v-row>
                </v-form>
              </v-expand-transition>
            </v-container>
          </v-card>
          <v-card>
            <v-data-table
              v-model="empresasSelected"
              id="tablaDeudas"
              :search="searchTablaDeudas"
              :headers="headers"
              :header-props="{ sortIcon: null }"
              class="elevation-1"
              :items="empresasDeuda"
              :loading="loading"
              :single-select="singleSelect"
              item-key="empId"
              show-select
              :footer-props="{
                'items-per-page-options': itemsPerPageOptions
              }"
              :items-per-page="itemPerPage"
            >
              <template v-slot:[`item.aporteContribuEsperado`]="{ item }">
                <span>{{ addDecimals(item.aporteContribuEsperado) }}</span>
              </template>
              <template v-slot:[`item.aporteContribuRecibido`]="{ item }">
                <span>{{ addDecimals(item.aporteContribuRecibido) }}</span>
              </template>
              <template v-slot:[`item.saldo`]="{ item }">
                <span>{{ addDecimals(item.saldo) }}</span>
              </template>
              <template v-slot:[`item.interes`]="{ item }">
                <span>{{ addDecimals(item.interes) }}</span>
              </template>
              <template v-slot:[`item.deuda`]="{ item }">
                <span>{{ addDecimals(item.deuda) }}</span>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="searchTablaDeudas"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`body.append`]="{ headers }">
                <tr class="totales text-end">
                  <td v-for="(header, i) in headers" :key="i">
                    <div v-if="header.value == 'cuit'">Totales</div>
                    <div
                      class="text--end"
                      v-if="header.value == 'aporteContribuEsperado'"
                    >
                      <strong> {{ addDecimals(totalEsperado) }}</strong>
                    </div>

                    <div v-if="header.value == 'aporteContribuRecibido'">
                      <strong> {{ addDecimals(totalRecibido) }}</strong>
                    </div>
                    <div v-if="header.value == 'saldo'">
                      <strong> {{ addDecimals(totalSaldo) }}</strong>
                    </div>
                    <div v-if="header.value == 'interes'">
                      <strong>{{ addDecimals(totalInteres) }}</strong>
                    </div>
                    <div v-if="header.value == 'deuda'">
                      <strong>{{ addDecimals(totalDeuda) }}</strong>
                    </div>
                    <div v-else></div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
          <div class="text-end pt-4">
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click="agregarTodos"
              :disabled="empresasDeuda.length == 0"
            >
              Agregar todos
            </v-btn>
            <v-btn
              small
              color="primary"
              class="mr-2"
              @click="addCarrito"
              :disabled="empresasSelected.length == 0"
            >
              Agregar seleccionados
            </v-btn>
          </div>

          <v-card>
            <v-toolbar-title class="mt-4"
              >Empresas seleccionadas</v-toolbar-title
            >
            <v-data-table
              id="carrito"
              :headers="headersCarrito"
              :header-props="{ sortIcon: null }"
              class="elevation-1"
              :search="searchTablaCarrito"
              :items="empresasDeudaCarrito"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              show-expand
              item-key="empId"
              :footer-props="{
                'items-per-page-options': itemsPerPageOptions
              }"
              :items-per-page="itemPerPage"
            >
              <template v-slot:[`item.aporteContribuEsperado`]="{ item }">
                <span>{{ addDecimals(item.aporteContribuEsperado) }}</span>
              </template>
              <template v-slot:[`item.aporteContribuRecibido`]="{ item }">
                <span>{{ addDecimals(item.aporteContribuRecibido) }}</span>
              </template>
              <template v-slot:[`item.saldo`]="{ item }">
                <span>{{ addDecimals(item.saldo) }}</span>
              </template>
              <template v-slot:[`item.interes`]="{ item }">
                <span>{{ addDecimals(item.interes) }}</span>
              </template>
              <template v-slot:[`item.deuda`]="{ item }">
                <span>{{ addDecimals(item.deuda) }}</span>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="searchTablaCarrito"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      @click="deleteFromCarrito(item)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Quitar</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <strong>Filtros aplicados:</strong> {{ cargarFiltros(item) }}
                </td>
              </template>
              <template v-slot:[`body.append`]="{ headers }">
                <tr class="totales text-end">
                  <td v-for="(header, i) in headers" :key="i">
                    <div v-if="header.value == 'cuit'">Totales</div>
                    <div v-if="header.value == 'aporteContribuEsperado'">
                      <strong> {{ addDecimals(totalEsperadoCarrito) }}</strong>
                    </div>

                    <div v-if="header.value == 'aporteContribuRecibido'">
                      <strong>{{ addDecimals(totalRecibidoCarrito) }}</strong>
                    </div>
                    <div v-if="header.value == 'saldo'">
                      <strong> {{ addDecimals(totalSaldoCarrito) }}</strong>
                    </div>
                    <div v-if="header.value == 'interes'">
                      <strong> {{ addDecimals(totalInteresCarrito) }}</strong>
                    </div>
                    <div v-if="header.value == 'deuda'">
                      <strong> {{ addDecimals(totalDeudaCarrito) }}</strong>
                    </div>
                    <div v-else></div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
          <v-row class="pr-4 pl-4 pt-4" justify="end">
            <v-btn
              color="primary"
              :disabled="empresasDeudaCarrito.length == 0"
              @click="nextStep"
            >
              Continuar
            </v-btn>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-row justify="start">
            <v-toolbar-title class="ml-4"
              >Proceso N° <strong>{{ procesoId }}</strong> | Fecha
              <strong>{{ fechaProceso }}</strong></v-toolbar-title
            >
          </v-row>
          <br />
          <template>
            <v-data-table
              id="tablaEmpresasSelected"
              v-if="inEditProcess"
              :headers="headers2"
              :header-props="{ sortIcon: null }"
              class="elevation-1"
              :items="actas"
              :search="searchTablaEmpresasSelected"
              :loading="loading"
              :footer-props="{
                'items-per-page-options': itemsPerPageOptions
              }"
              :items-per-page="itemPerPage"
            >
              <template v-slot:[`item.aporteContribuEsperado`]="{ item }">
                <span>{{ addDecimals(item.aporteContribuEsperado) }}</span>
              </template>
              <template v-slot:[`item.aporteContribuRecibido`]="{ item }">
                <span>{{ addDecimals(item.aporteContribuRecibido) }}</span>
              </template>
              <template v-slot:[`item.saldo`]="{ item }">
                <span>{{ addDecimals(item.saldo) }}</span>
              </template>
              <template v-slot:[`item.interes`]="{ item }">
                <span>{{ addDecimals(item.interes) }}</span>
              </template>
              <template v-slot:[`item.deuda`]="{ item }">
                <span>{{ addDecimals(item.deuda) }}</span>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="searchTablaEmpresasSelected"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      @click="editEmpresa(true, item)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      @click="deleteEmpresa(item)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Quitar</span>
                </v-tooltip>
              </template>
              <template v-slot:[`body.append`]="{ headers }">
                <tr class="totales text-end">
                  <td v-for="(header, i) in headers" :key="i">
                    <div v-if="header.value == 'cuit'">Totales</div>
                    <div v-if="header.value == 'aporteContribuEsperado'">
                      <strong>{{ addDecimals(totalEsperadoSelected) }}</strong>
                    </div>

                    <div v-if="header.value == 'aporteContribuRecibido'">
                      <strong> {{ addDecimals(totalRecibidoSelected) }}</strong>
                    </div>
                    <div v-if="header.value == 'saldo'">
                      <strong>{{ addDecimals(totalSaldoSelected) }}</strong>
                    </div>
                    <div v-if="header.value == 'interes'">
                      <strong>{{ addDecimals(totalInteresSelected) }}</strong>
                    </div>
                    <div v-if="header.value == 'deuda'">
                      <strong>{{ addDecimals(totalDeudaSelected) }}</strong>
                    </div>
                    <div v-else></div>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <br /><br />
            <div v-if="!inEditProcess && e1 == 2">
              <PeriodosDDJJ
                :actaId="actaFiscalizacionId"
                :empresaNom="empresaNombre"
                :empresaId="empresaId"
                @close="editEmpresa"
              ></PeriodosDDJJ>
            </div>
          </template>
          <v-card class="pa-0 elevation-4">
            <v-col v-if="inEditProcess" cols="12" class="pl-0">
              <v-btn-toggle dense v-model="text" color="primary" group>
                <v-btn
                  value="Generación de actas"
                  @click="isGeneracion = true"
                  :disabled="banderaActaAsociada"
                >
                  Generación de actas
                </v-btn>
                <v-btn
                  value="Asociar acta sin DDJJ"
                  @click="isGeneracion = false"
                  v-if="actas.length == 1"
                >
                  Asociar acta a empresa
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-row v-show="isGeneracion">
              <v-container>
                <v-form
                  v-model="isForm2Valid"
                  ref="filters-form2"
                  id="filters-form2"
                  @submit.prevent="nextStep()"
                >
                  <v-row v-if="inEditProcess">
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaActualSelected"
                            label="Fecha"
                            :append-icon="calendarIcon"
                            v-bind="attrs"
                            hint="Formato DD/MM/AAAA"
                            @blur="
                              fechaActual = parseDateToIso(fechaActualSelected)
                            "
                            outlined
                            dense
                            v-on="on"
                            :rules="[
                              (!!fechaActualSelected &&
                                !!vencimientoSelected &&
                                new Date(parseDateToIso(fechaActualSelected)) <=
                                  new Date(
                                    parseDateToIso(vencimientoSelected)
                                  )) ||
                                'Formato incorrecto'
                            ]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fechaActual"
                          no-title
                          @change="
                            fechaActualSelected = formatDate(fechaActual)
                          "
                          @input="menu4 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="vencimientoSelected"
                            label="Vencimiento acta"
                            :append-icon="calendarIcon"
                            v-bind="attrs"
                            hint="Formato DD/MM/AAAA"
                            @blur="
                              vencimiento = parseDateToIso(vencimientoSelected)
                            "
                            outlined
                            dense
                            v-on="on"
                            :rules="[
                              (!!fechaActualSelected &&
                                !!vencimientoSelected &&
                                new Date(parseDateToIso(fechaActualSelected)) <=
                                  new Date(
                                    parseDateToIso(vencimientoSelected)
                                  )) ||
                                'Formato incorrecto'
                            ]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="vencimiento"
                          no-title
                          @change="
                            vencimientoSelected = formatDate(vencimiento)
                          "
                          :min="fechaActual"
                          @input="menu3 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="estadoSelected"
                        :items="estado"
                        item-text="value"
                        item-value="id"
                        label="Estado inicial"
                        outlined
                        dense
                        :rules="rules.required"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="inspectorSelected"
                        label="Inspector"
                        :items="inspectores"
                        item-text="provNom"
                        item-value="provId"
                        dense
                        outlined
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="entidadSelected"
                        :items="entidades"
                        item-text="value"
                        item-value="id"
                        label="Entidad fiscalizadora"
                        outlined
                        dense
                        :rules="rules.required"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-btn
                    v-if="!inEditProcess"
                    class="ma-2"
                    color="primary"
                    @click="readyButton"
                    id="btnListo"
                  >
                    Listo
                  </v-btn>
                  <div v-else>
                    <v-btn
                      class="ma-2"
                      :disabled="!inEditProcess"
                      text
                      @click="volver"
                      >Volver
                    </v-btn>
                    <v-btn
                      type="submit"
                      form="filters-form2"
                      class="ma-2"
                      :disabled="
                        !inEditProcess || actas.length == 0 || !isForm2Valid
                      "
                      color="primary"
                    >
                      Confirmar
                    </v-btn>
                  </div>
                </v-row>
              </v-container>
            </v-row>
            <v-card-text class="py-0">
              <v-row v-show="!isGeneracion">
                <v-col cols="12">
                  <v-data-table
                    :headers="headersActas"
                    :items="actasRelacionProceso"
                    class="elevation-1"
                    item-key="actasFiscalizacionId"
                    show-select
                    single-select
                    v-model="empSeleccionada"
                  >
                    <template>
                      <v-icon v-if="empSeleccionada">
                        mdi-checkbox-marked</v-icon
                      >
                    </template>
                  </v-data-table>
                  <v-card-actions class="pt-2">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="empSeleccionada.length == 0"
                      elevation="2"
                      @click="asociarActaEmp()"
                    >
                      Asociar y confirmar proceso
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <DeleteDialog
      :textDelete="textDelete"
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deleteEmpresaConfirm()"
    />
    <ConfirmDialog
      :text="textConfirmDialog"
      :openConfirm.sync="openConfirmDialog"
      @update:openConfirm="openConfirmDialog = false"
      @onConfirm="confirmAsociarActa(saveRelacionActaSinProcesoDDJJ)"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions, mapGetters } from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import FiltersSelected from "@/components/shared/FiltersSelected";
import PeriodosDDJJ from "@/components/modules/cuotas/aportes/PeriodosDDJJ.vue";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mask } from "vue-the-mask";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "NuevoProcesoFiscalizacion",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    PeriodosDDJJ,
    DeleteDialog,
    GoBackBtn,
    ConfirmDialog
  },

  data: vm => ({
    fechaDesdeSelected: vm.formatDate(new Date().toISOString().substr(0, 10)),
    fechaHastaSelected: vm.formatDate(new Date().toISOString().substr(0, 10)),
    fechaActualSelected: vm.formatDate(new Date().toISOString().substr(0, 10)),
    vencimientoSelected: null,
    infoIcon: enums.icons.SNB_INFO,
    fechaInteresSelected: null,
    empSeleccionada: [],
    routeToGo: "GestiónProcesosFiscalización",
    searchIcon: enums.icons.SEARCH,
    registrosEspDj: false,
    itemsPerPageOptions: [10, 20, 30, 40, 50],
    itemPerPage: 10,
    textConfirmDialog: "",
    openConfirmDialog: false,
    filtersApplied: [],
    empresasDeudaCarrito: [],
    editedIndex: -1,
    singleSelect: false,
    singleExpand: false,
    expanded: [],
    searchTablaDeudas: "",
    searchTablaCarrito: "",
    searchTablaEmpresasSelected: "",
    inspectorSelected: null,
    inspectores: [],
    periodoDesde: null,
    e1: 1,
    empresaId: 0,
    rules: rules,
    empresaNombre: "",
    isGeneracion: true,
    periodoHasta: null,
    actaFiscalizacionId: 0,
    procesoId: 0,
    fechaProceso: "",
    totalSaldo: 0,
    totalInteres: 0,
    totalDeuda: 0,
    totalRecibido: 0,
    totalEsperado: 0.0,
    totalSaldoCarrito: 0,
    totalInteresCarrito: 0,
    totalDeudaCarrito: 0,
    totalRecibidoCarrito: 0,
    totalEsperadoCarrito: 0,
    totalSaldoSelected: 0,
    totalInteresSelected: 0,
    totalDeudaSelected: 0,
    totalRecibidoSelected: 0,
    totalEsperadoSelected: 0,
    openDeleteDialog: false,
    headers: [
      {
        text: "Empresa",
        align: "start",
        value: "empNombre"
      },
      { text: "CUIT", value: "cuit" },
      { text: "Esperado", value: "aporteContribuEsperado", align: "end" },
      { text: "Recibido", value: "aporteContribuRecibido", align: "end" },
      { text: "Saldo", value: "saldo", align: "end" },
      { text: "Interés", value: "interes", align: "end" },
      { text: "Deuda", value: "deuda", align: "end" }
    ],
    headersCarrito: [
      {
        text: "Empresa",
        align: "start",
        value: "empNombre"
      },
      { text: "CUIT", value: "cuit" },
      { text: "Esperado", value: "aporteContribuEsperado", align: "end" },
      { text: "Recibido", value: "aporteContribuRecibido", align: "end" },
      { text: "Saldo", value: "saldo", align: "end" },
      { text: "Interés", value: "interes", align: "end" },
      { text: "Deuda", value: "deuda", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    headers2: [
      {
        text: "Empresa",
        align: "start",
        value: "empNombre"
      },
      { text: "CUIT", value: "cuit" },
      { text: "Esperado", value: "aporteContribuEsperado", align: "end" },
      { text: "Recibido", value: "aporteContribuRecibido", align: "end" },
      { text: "Saldo", value: "saldo", align: "end" },
      { text: "Interés", value: "interes", align: "end" },
      { text: "Deuda", value: "deuda", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    headersActas: [
      {
        text: "Acta",
        align: "start",
        value: "actasFiscalizacionId",
        sortable: false
      },
      {
        text: "Empresa",
        align: "start",
        value: "empNombre",
        sortable: false
      },
      {
        text: "CUIT",
        align: "start",
        value: "cuit",
        sortable: false
      },
      {
        text: "Fecha",
        align: "start",
        value: "fecha"
      },
      {
        text: "Fecha de vencimiento",
        align: "start",
        value: "fechaVenc",
        sortable: false
      },
      {
        text: "Importe",
        align: "end",
        value: "importe",
        sortable: false
      },
      {
        text: "Estado actual",
        align: "start",
        value: "estadoActual",
        sortable: false
      },
      {
        text: "Inspector",
        align: "start",
        value: "inspector",
        sortable: false
      },
      {
        text: "Email",
        align: "start",
        value: "email",
        sortable: false
      }
    ],
    empresaSelected: null,
    entidadSelected: null,
    fechaInteresOnFocus: false,
    afiliadoSelected: null,
    empresasDeuda: [],
    empresasSelected: [],
    banderaActaAsociada: false,
    showFilters: true,
    showFiltersMore: false,
    showFiltersSelected: false,
    saldoSelected: 1,
    estadoSelected: null,
    inEditProcess: true,
    calendarIcon: enums.icons.CALENDAR,
    titleNew: enums.titles.NUEVO_PROCESO_FISCALIZACION,
    titleEdit: enums.titles.EDITAR_PROCESO_FISCALIZACION,
    textDelete: "",
    isFormValid: true,
    isForm2Valid: true,
    loading: false,
    currentPage: 0,
    timer: null,
    empresas: [],
    entidades: [],
    actasRelacionProceso: [],
    afiliados: [],
    empresaSearchInput: null,
    empresaTextInput: null,
    afiliadoSearchInput: null,
    afiliadoTextInput: null,
    actaToDelete: {},
    text: "Generación de actas",
    esFiscalizado: false,
    esperadoXCategoria: false,
    convenios: [],
    fechaActual: new Date().toISOString().substr(0, 10),
    vencimiento: null,
    fechaDesde: new Date().toISOString().substr(0, 10),
    fechaHasta: new Date().toISOString().substr(0, 10),
    fechaInteres: null,
    fechaInteresValidacion: new Date().toISOString().substr(0, 10),
    fechaPeriodoValidacion: new Date().toISOString().substr(0, 10),
    menu1: false,
    menu3: false,
    menu4: false,
    actas: [],
    menu2: false,
    idEmp: null,
    menu5: false,
    saldo: [
      {
        id: 1,
        value: "Total"
      },
      {
        id: 2,
        value: "Parcial"
      }
    ],
    estado: [],
    openModalEdit: false,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    numeroActaSinDj: null,
    actaConDj: null,
    datos: []
  }),

  computed: {
    ...mapGetters({
      filtersTop: "aportes/nuevasActasFiltersTop"
    }),
    isEditing() {
      return this.$route.params.procesoId;
    }
  },
  created() {
    if (this.$route.params.procesoId) {
      this.procesoId = this.$route.params.procesoId;
      this.volver();
    }
    this.$bus.$on("refreshTable", () => {
      this.applyFilters();
      this.loading = false;
    });

    this.$bus.$on("resetEmpresasSelected", () => {
      this.inEditProcess = true;
      this.actas = [];
      var procesoId = this.procesoId;
      this.getActasByProcesoId({ procesoId }).then(res => {
        this.actas = res;
        this.calcularTotalesCarrito();
      });
    });
  },
  mounted() {
    this.getNuevasActasFiltersTop();
    this.options = { page: 1, itemsPerPage: 5 };
    this.currentPage = this.options.page;
  },
  watch: {
    empresasDeuda(val) {
      if (val.length > 0) {
        this.totalSaldo = this.empresasDeuda
          .map(x => x.saldo)
          .reduce((a, b) => a + b, 0);
        this.totalInteres = this.empresasDeuda
          .map(x => x.interes)
          .reduce((a, b) => a + b, 0);
        this.totalEsperado = this.empresasDeuda
          .map(x => x.aporteContribuEsperado)
          .reduce((a, b) => a + b, 0);
        this.totalRecibido = this.empresasDeuda
          .map(x => x.aporteContribuRecibido)
          .reduce((a, b) => a + b, 0);
        this.totalDeuda = this.empresasDeuda
          .map(x => x.deuda)
          .reduce((a, b) => a + b, 0);
      } else {
        this.totalSaldo = 0;
        this.totalInteres = 0;
        this.totalEsperado = 0;
        this.totalRecibido = 0;
        this.totalDeuda = 0;
      }
    },
    filtersTop() {
      this.convenios = this.filtersTop.convenios.items;
      this.estado = this.filtersTop.estados.items;
      this.entidades = this.filtersTop.entidades.items;
      if (this.convenios && !this.convenios.find(x => x.value === "Todos"))
        this.convenios.unshift({ id: 0, value: "Todos" });
      if (this.afiliados && !this.afiliados.find(x => x.value === "Todos"))
        this.afiliados.unshift({ id: 0, value: "Todas" });
      if (this.empresas && !this.empresas.find(x => x.value === "Todas"))
        this.empresas.unshift({ id: 0, value: "Todas" });
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    afiliadoTextInput(val) {
      if (val && val.length >= 3) {
        this.getAfiliadosFilter();
      }
    },
    empresaTextInput(val) {
      if (val && val.length >= 3) {
        this.getEmpresasFilter();
      }
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getNuevasActasFiltersTop: "aportes/getNuevasActasFiltersTop",
      getActasARelacionarProceso: "aportes/getActasARelacionarProceso",
      saveRelacionActaSinProcesoDDJJ: "aportes/saveRelacionActaSinProcesoDDJJ",
      confirmarAsociaProcesoActaExistente:
        "aportes/confirmarAsociaProcesoActaExistente",
      getNuevasActasInfoTable: "aportes/getNuevasActasInfoTable",
      fetchAfiliadosByQuery: "aportes/fetchAfiliadosByQuery",
      fetchEmpresasByQuery: "aportes/fetchEmpresasByQuery",
      getEmpresaById: "aportes/getEmpresaById",
      getActasByProcesoId: "aportes/getActasByProcesoId",
      grabarProcesoFiscalizacion: "aportes/grabarProcesoFiscalizacion",
      deleteActaById: "aportes/deleteActaById",
      confirmarGrabado: "aportes/confirmarGrabado",
      getProcesoFiscalizacionById: "aportes/getProcesoFiscalizacionById",
      fetchInspectores: "aportes/fetchInspectores"
    }),
    resetForm() {
      this.$refs["filters-form"].reset();
      this.filtersApplied = [];
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },

    closeOpenMoreFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFiltersMore = !this.showFiltersMore;
    },
    customFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = this.filtersApplied.filter(x => {
        return (
          x.label !== "Período DDJJ desde" && x.label !== "Período DDJJ hasta"
        );
      });
      if (this.periodoDesde) {
        this.filtersApplied.push({
          key: "periodoDdjj",
          label: "Período DDJJ desde",
          model: this.periodoDesde
        });
      }
      if (this.periodoHasta) {
        this.filtersApplied.push({
          key: "periodoDdjj",
          label: "Período DDJJ hasta",
          model: this.periodoHasta
        });
      }
      if (this.esFiscalizado) {
        const esFisc = "SI";
        this.filtersApplied.splice(1, 1, {
          key: "incluyeFiscalizados",
          label: "Es fiscalizado",
          model: esFisc
        });
      }
      if (this.esperadoXCategoria) {
        const determinarEsp = "SI";
        this.filtersApplied.splice(2, 1, {
          key: "incluirRelacionados",
          label: "Determinar esperado según el sueldo de la categoría",
          model: determinarEsp
        });
      }
      this.filtersApplied.push(
        {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        },
        {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        },
        {
          key: "vencimientoHasta",
          label: "Fecha cálculo interés",
          model: this.fechaInteresSelected
        }
      );
    },
    async applyFilters() {
      this.filtersApplied = [];
      if (this.$refs["filters-form"].validate()) {
        this.empresasSelected = [];
        this.inEditProcess = true;
        this.filtersApplied = this.formatFilters(this.$refs["filters-form"]);
        this.loading = true;
        this.showFiltersSelected = true;
        this.customFiltersApplied();
        this.showFilters = false;
        // configuro los parametros como los espera el endpoint
        const bodyParams = {
          periodoDesde: this.periodoDesde,
          periodoHasta: this.periodoHasta,
          osId: this.filtersTop.convenios.model
            ? this.filtersTop.convenios.model
            : -1,
          fechaDesde: this.parseDate(this.fechaDesdeSelected),
          fechaHasta: this.parseDate(this.fechaHastaSelected),
          empresaId: this.empresaSelected ? this.empresaSelected : -1,
          benId: this.afiliadoSelected ? this.afiliadoSelected : -1,
          incluyeFiscalizados: this.esFiscalizado,
          esperadoSegunCategoria: this.esperadoXCategoria,
          tipoSaldo:
            typeof this.saldoSelected === "undefined" ? -1 : this.saldoSelected,
          fechaHastaCalculoInteres: this.parseDate(this.fechaInteresSelected)
        };

        try {
          const response = await this.getNuevasActasInfoTable({ bodyParams });
          if (response.status === 200) {
            this.datos = response.data.data;
            this.empresasDeuda = this.datos.listadoAportes;
          }
          this.loading = false;
        } catch {
          this.loading = false;
        }
      }
    },
    async getActasAProceso() {
      const actasPro = await this.getActasARelacionarProceso(this.idEmp);
      this.actasRelacionProceso = actasPro;
    },
    async asociarActaEmp() {
      this.openConfirmDialog = true;
      this.numeroActaSinDj = this.empSeleccionada[0].actasFiscalizacionId;
      this.textConfirmDialog = `¿Desea asociar el acta ${this.numeroActaSinDj} al proceso ${this.procesoId}? El proceso de fiscalización se confirmará automáticamente.`;
    },
    async confirmAsociarActa() {
      const data = {
        actaSinDdjj: this.numeroActaSinDj,
        actaConDdjj: this.actaConDj
      };
      const res = await this.saveRelacionActaSinProcesoDDJJ(data);
      if (res.status === 200) {
        this.banderaActaAsociada = true;
        this.openConfirmDialog = false;
        this.setAlert({ type: "success", message: "Acta asociada con éxito" });

        this.confirmarProcesoActaRelacionada();
      }
    },
    async confirmarProcesoActaRelacionada() {
      const data = {
        procesoFiscalizacionId: this.procesoId
      };
      const asociarActa = await this.confirmarAsociaProcesoActaExistente(data);
      if (asociarActa.status === 200) {
        this.setAlert({
          type: "success",
          message: "Proceso confirmado con éxito"
        });
        this.$router.push({ name: "GestiónProcesosFiscalización" });
      }
    },

    getEmpresasFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        const empresas = await this.fetchEmpresasByQuery({
          input: this.empresaTextInput
        });
        this.empresas = empresas;
      }, 500);
    },
    getAfiliadosFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        const afiliados = await this.fetchAfiliadosByQuery({
          input: this.afiliadoTextInput
        });
        this.afiliados = afiliados;
      }, 500);
    },
    formatFilters(ref) {
      this.filtersApplied = [];
      if (typeof ref === "undefined") return;
      return ref.$children
        .map(f => {
          if (f.value)
            return { key: f.id, label: f.label, model: f.selectedItems };
          return null;
        })
        .filter(f => f);
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    editEmpresa(open, item) {
      var empresaSelected = item.empId;
      const empresa = this.getEmpresaById({ empresaSelected }).then(res => {
        this.empresaNombre = res.data.nombre;
      });
      this.actaFiscalizacionId = item.actasFiscalizacionId;
      this.openModalEdit = open;
      this.inEditProcess = false;
      this.empresaId = empresaSelected;
      this.$bus.$emit("openModalEditEmpresaActa", this.openModalEdit);
    },
    async grabarProceso() {
      const bodyParams = [];
      var proceso = this.procesoId;
      var procesoId;
      var empresas = this.empresasDeudaCarrito;
      for (var i = 0; i < empresas.length; i++) {
        var empresaDesignated = empresas[i];
        bodyParams.push({
          actasFiscalizacionId: empresaDesignated.actasFiscalizacionId,
          empId: empresaDesignated.empId,
          empresaNom: empresaDesignated.empresaNom,
          cuit: empresaDesignated.cuit,
          esperado: empresaDesignated.aporteContribuEsperado,
          recibido: empresaDesignated.aporteContribuRecibido,
          saldo: empresaDesignated.saldo,
          interes: empresaDesignated.interes,
          email: empresaDesignated.email,
          telefono: empresaDesignated.telefono,
          filtrosAplicados: empresaDesignated.filtrosAplicados,
          aportesEsperadosRecibidosDeta:
            empresaDesignated.aportesEsperadosRecibidosDeta
        });
      }
      try {
        const response = await this.grabarProcesoFiscalizacion({
          proceso,
          bodyParams
        });
        this.procesoId = response.data;
        procesoId = this.procesoId;
        this.getActasByProcesoId({ procesoId }).then(res => {
          this.actas = res;
          this.actaConDj = this.actas.map(x => x.actasFiscalizacionId)[0];
          this.idEmp = res.map(x => x.empId)[0];
          this.getActasAProceso();
          this.calcularTotalesCarrito();
        });
        this.getProcesoFiscalizacionById({ procesoId }).then(res => {
          this.fechaProceso = res.fecha;
        });
      } catch {}
      // const response = await this.grabarProcesoFiscalizacion({ bodyParams }).then((res) => {

      //this.procesoId = response;});
    },
    async confirmar() {
      const inspector = this.inspectores.find(
        x => x.provId === this.inspectorSelected
      );
      const bodyParams = {
        procesoFiscalizacionId: this.procesoId,
        fecha: this.parseDateToIso(this.fechaActualSelected),
        fechaVencimientoActa: this.parseDateToIso(this.vencimientoSelected),
        estado: this.estadoSelected.toString(),
        inspectorId: typeof inspector !== "undefined" ? inspector.provId : null,
        anyActaEnEstadoNoInicial: false,
        entidadFiscalizadoraId: this.entidadSelected.toString()
      };
      const response = await this.confirmarGrabado({ bodyParams });
      if (response.status === 200) {
        this.setAlert({
          type: "success",
          message: "Proceso N°" + this.procesoId + " confirmado."
        });
        this.$router.push({ name: "GestiónProcesosFiscalización" });
      }
      this.reset();
    },
    deleteEmpresa(item) {
      this.textDelete =
        "Al eliminar esta empresa, se eliminará el acta asociada.";
      this.actaToDelete = item;
      this.openDeleteDialog = true;
    },
    async deleteEmpresaConfirm() {
      var item = this.actaToDelete;
      this.editedIndex = this.actas.indexOf(item);
      this.actas.splice(this.editedIndex, 1);
      this.quitarEmpresa(item);
      this.actaToDelete = {};
      this.textDelete = null;
    },

    async quitarEmpresa(item) {
      var actaId = item.actasFiscalizacionId;
      const res = await this.deleteActaById({ actaId });

      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.calcularTotalesCarrito();
      }
    },
    deleteFromCarrito(item) {
      this.editedIndex = this.empresasDeudaCarrito.indexOf(item);
      this.empresasDeudaCarrito.splice(this.editedIndex, 1);
      this.calcularTotalesCarrito(1);
      if (this.empresasDeuda.length > 0) {
        this.empresasDeuda = this.empresasDeuda.filter(
          x => x.empId !== item.empId
        );
      }
      this.empresasDeuda.push(item);
    },

    formatFiltersPassed() {
      var periD = this.periodoDesde
        ? "período desde: " + this.periodoDesde.toString() + ", "
        : "";
      var periodoHasta = this.periodoHasta
        ? "período hasta: " + this.periodoHasta.toString() + ", "
        : "";
      var fechaDesde = this.fechaDesdeSelected.toString();
      var fechaHasta = this.fechaHastaSelected.toString();
      var fechaInteres = this.fechaInteresSelected.toString();
      var empresa = this.empresaTextInput
        ? ", Empresa: " + this.empresaTextInput.toString()
        : "";
      var convenio =
        document.getElementById("convAutocomplete").value != ""
          ? ", Convenio: " + document.getElementById("convAutocomplete").value
          : "";
      var afi = this.afiliadoTextInput
        ? ", Afiliado: " + this.afiliadoTextInput.toString()
        : "";
      var incluyeFiscalizados =
        this.esFiscalizado == 0 || this.esFiscalizado == "undefined"
          ? "No"
          : "sí";
      var esperadoSegunCategoria =
        this.esperadoXCategoria == 0 || this.esperadoXCategoria == "undefined"
          ? "No"
          : "sí";
      var tipoSaldo =
        typeof this.saldoSelected === "undefined" || this.saldoSelected == 1
          ? "Total"
          : "Parcial";

      var texto =
        periD +
        periodoHasta +
        "Fecha desde: " +
        fechaDesde +
        ", Fecha hasta: " +
        fechaHasta +
        empresa +
        convenio +
        afi +
        ", Incluye fiscalizados: " +
        incluyeFiscalizados +
        "Aporte esperado según categoria" +
        esperadoSegunCategoria +
        ", Tipo de saldo: " +
        tipoSaldo +
        ", Fecha de cálculo de interés: " +
        fechaInteres;
      return texto;
    },
    cargarFiltros(item) {
      if (
        item.actasFiscalizacionId == null ||
        item.actasFiscalizacionId == "undefined"
      ) {
        return item.filtrosAplicados;
      } else return item.filtersApplied;
    },

    async nextStep() {
      switch (this.e1) {
        case 1:
          await this.grabarProceso();
          const respo = await this.fetchInspectores();
          this.inspectores = respo;
          return (this.e1 = 2);

        case 2:
          await this.confirmar();
          return (this.e1 = 1);
      }
    },
    reset() {
      this.empresasDeudaCarrito = [];
      this.empresasSelected = [];
      this.empresasDeuda = [];
      this.totalSaldoCarrito = 0;
      this.totalInteresCarrito = 0;
      this.totalEsperadoCarrito = 0;
      this.totalRecibidoCarrito = 0;
      this.totalSaldoSelected = 0;
      this.totalInteresSelected = 0;
      this.totalEsperadoSelected = 0;
      this.totalRecibidoSelected = 0;
    },
    volver() {
      this.empresasSelected = [];
      this.inEditProcess = true;
      this.e1 = 1;
      this.openModalEdit = false;
      var procesoId = this.procesoId;
      this.getActasByProcesoId({ procesoId }).then(res => {
        this.empresasDeudaCarrito = res;
        this.calcularTotalesCarrito();
        this.calcularTotalesCarrito(1);
      });
      const value = null;
    },
    readyButton() {
      this.inEditProcess = true;
      this.openModalEdit = false;
      var procesoId = this.procesoId;
      this.getActasByProcesoId({ procesoId }).then(res => {
        this.actas = res;
        this.calcularTotalesCarrito();
      });
    },
    agregarTodos() {
      this.empresasSelected = [...this.empresasDeuda];
      this.addCarrito();
    },
    addCarrito() {
      var encontrada = false;
      var empresasEncontradas = [];
      if (this.empresasDeudaCarrito.length > 0) {
        for (var i = 0; i < this.empresasSelected.length; i++) {
          for (var j = 0; j < this.empresasDeudaCarrito.length; j++) {
            if (
              this.empresasDeudaCarrito[j] != "undefined" &&
              this.empresasDeudaCarrito[j].empId ==
                this.empresasSelected[i].empId
            ) {
              encontrada = true;
              empresasEncontradas.push(this.empresasSelected[i]);
              break;
            }
          }
          if (encontrada == false) {
            this.empresasDeudaCarrito.push(this.empresasSelected[i]);
            var pos = this.empresasDeudaCarrito.length - 1;
            this.empresasDeudaCarrito[
              pos
            ].filtrosAplicados = this.formatFiltersPassed();
          }
          encontrada = false;
        }
        if (empresasEncontradas.length > 0) {
          var salida = "";
          if (empresasEncontradas.length < 4) {
            for (var i = 0; i < empresasEncontradas.length; i++) {
              i == empresasEncontradas.length - 2
                ? (salida += empresasEncontradas[i].empNombre + " y ")
                : i == empresasEncontradas.length - 1
                ? (salida += empresasEncontradas[i].empNombre + " ")
                : (salida += empresasEncontradas[i].empNombre + ", ");
            }
            //   empresasEncontradas.forEach(element => {
            //        salida +=element.empNombre+' ';
            //      });
          } else {
            for (var i = 0; i < 3; i++) {
              i == empresasEncontradas.length - 2
                ? (salida += empresasEncontradas[i].empNombre + " y ")
                : i == empresasEncontradas.length - 1
                ? (salida += empresasEncontradas[i].empNombre + " ")
                : (salida += empresasEncontradas[i].empNombre + ", ");
              // salida +=empresasEncontradas[i].empNombre+' ';
            }
            salida += `y más empresas`;
          }
          if (empresasEncontradas.length > 1) {
            this.setAlert({
              type: "warning",
              message:
                salida +
                ` ya están en el proceso.
                      Para agregarlas, deberás primero quitarlas de la tabla inferior.`
            });
          }
          if (empresasEncontradas.length == 1) {
            this.setAlert({
              type: "warning",
              message:
                salida +
                ` ya está en el proceso.
                      Para agregarla, deberás primero quitarla de la tabla inferior.`
            });
          }
        }
        this.selectEmpresasToCarrito();
      } else {
        this.selectEmpresasToCarrito();

        this.empresasDeudaCarrito.forEach(element => {
          element.filtrosAplicados = this.formatFiltersPassed();
        });
      }
      this.calcularTotalesCarrito(1);
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    selectEmpresasToCarrito() {
      if (this.empresasDeudaCarrito.length > 0) {
        this.empresasDeudaCarrito.push.apply(this.empresasSelected);
      } else {
        this.empresasDeudaCarrito = [...this.empresasSelected];
      }
      this.empresasSelected = [];
      this.empresasDeuda = this.empresasDeuda.filter(
        x => !this.empresasDeudaCarrito.includes(x)
      );
    },
    calcularTotalesCarrito(origen) {
      if (origen == 1) {
        //carrito
        this.totalSaldoCarrito = 0;
        this.totalInteresCarrito = 0;
        this.totalEsperadoCarrito = 0;
        this.totalRecibidoCarrito = 0;
        this.totalDeudaCarrito = 0;

        this.totalSaldoCarrito = this.empresasDeudaCarrito
          .map(x => x.saldo)
          .reduce((a, b) => a + b, 0);
        this.totalInteresCarrito = this.empresasDeudaCarrito
          .map(x => x.interes)
          .reduce((a, b) => a + b, 0);
        this.totalEsperadoCarrito = this.empresasDeudaCarrito
          .map(x => x.aporteContribuEsperado)
          .reduce((a, b) => a + b, 0);
        this.totalRecibidoCarrito = this.empresasDeudaCarrito
          .map(x => x.aporteContribuRecibido)
          .reduce((a, b) => a + b, 0);
        this.totalDeudaCarrito = this.empresasDeudaCarrito
          .map(x => x.deuda)
          .reduce((a, b) => a + b, 0);
      } else {
        //step 2
        this.totalSaldoSelected = 0;
        this.totalInteresSelected = 0;
        this.totalEsperadoSelected = 0;
        this.totalRecibidoSelected = 0;
        this.totalDeudaSelected = 0;

        this.totalSaldoSelected = this.actas
          .map(x => x.saldo)
          .reduce((a, b) => a + b, 0);
        this.totalInteresSelected = this.actas
          .map(x => x.interes)
          .reduce((a, b) => a + b, 0);
        this.totalEsperadoSelected = this.actas
          .map(x => x.aporteContribuEsperado)
          .reduce((a, b) => a + b, 0);
        this.totalRecibidoSelected = this.actas
          .map(x => x.aporteContribuRecibido)
          .reduce((a, b) => a + b, 0);
        this.totalDeudaSelected = this.actas
          .map(x => x.deuda)
          .reduce((a, b) => a + b, 0);
      }
    },
    formatPeriodo(periodo) {
      const mes = periodo.slice(4, 6);
      const año = periodo.slice(0, 4);
      const periodoFecha = `01/${mes}/${año}`;
      return periodoFecha;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.icon-readonly {
  cursor: context-menu;
}
.totales {
  font-weight: 500;
}
.v-label.theme--light {
  left: 0px;
  right: auto;
  position: relative;
  margin-bottom: 0px;
}
#btnListo {
  margin-left: 10px;
  margin-right: 10px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
